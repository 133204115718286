import "./src/styles/index.scss";
import {
    BalApp,
    // useBaloiseDesignSystem,
} from "@baloise/design-system-next-components-react";

import React from "react";
import { RecoilRoot } from "recoil";

export const wrapRootElement = ({ element }) => {
    // useBaloiseDesignSystem();
    return (
        <RecoilRoot>
            <BalApp>{element}</BalApp>
        </RecoilRoot>
    );
};
